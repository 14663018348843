import paper from 'paper/dist/paper-core';
import {autorun, reaction} from 'mobx';
import {LineModelInstance, LineStoreInstance, PolygonModelInstance, PolygonStoreInstance, StoreInstance} from './Store';
import {createAddTool} from './tools/Add';
import {createMoveTool} from './tools/Move';
import {createAddVectorTool} from './tools/AddLine';
import {createMoveLineTool} from './tools/MoveLine';
import {ToolName} from './tools/ToolName';
import {Locale} from './Locale';
import {addDisposer} from 'mobx-state-tree';
import {renderPolygon} from './items/Polygon';
import {renderLine} from './items/Line';

export function createPaperProject(
  canvas: HTMLCanvasElement,
  store: StoreInstance,
  scale: number,
  editable: boolean,
  locale: Locale
) {
  const scope: paper.PaperScope = new paper.PaperScope();
  const validScale = scale > 0 ? scale : 1;

  scope.setup(canvas);
  scope.view.scale(validScale, new scope.Point([0, 0]));
  scope.settings.hitTolerance = Math.round(2 / validScale);

  const disposers = [
    () => {
      scope.project.clear();
      scope.project.view.remove();
      // @ts-ignore
      scope.remove();
    }
  ];

  disposers.push(
    renderItems(
      scope,
      validScale,
      store.itemStore.polygonStore,
      () => store.itemStore.polygonStore.items,
      renderPolygon
    )
  );
  disposers.push(
    renderItems(
      scope,
      validScale,
      store.itemStore.lineStore,
      () => store.itemStore.lineStore.items,
      renderLine
    )
  );

  if (editable) {
    const CustomTool = crateCustomToolClass(scope);
    const tools = [
      createMoveTool(CustomTool, scope, store, validScale),
      createAddTool(CustomTool, scope, store, validScale, locale),
      createAddVectorTool(CustomTool, scope, store, validScale),
      createMoveLineTool(CustomTool, scope, store, validScale)
    ];

    disposers.push(
      autorun(() => {
        canvas.style.cursor = store.cursor;
      })
    );

    disposers.push(
      reaction(
        () => store.activeTool,
        (activeTool) => {
          const tool = tools.find((tool) => tool.name === activeTool);
          if (tool && tool !== scope.tool) {
            (scope.tool as ICustomTool).deactivate();
            tool.activate();
          }
        },
        {
          fireImmediately: true
        }
      )
    );
  }

  return () => {
    disposers.forEach((d) => d());
  };
}

export interface ICustomToolClass {
  new (name: ToolName, activators: Array<(() => void) | (() => () => void)>): ICustomTool;
}

interface ICustomTool extends paper.Tool {
  readonly name: ToolName;
  deactivate(): void;
}

function crateCustomToolClass(scope: paper.PaperScope): ICustomToolClass {
  class CustomTool extends scope.Tool implements ICustomTool {
    constructor(public name: ToolName, private activators: Array<(() => void) | (() => () => void)>) {
      super();
      this.activate();
    }
    private disposers: Array<() => void> = [];
    activate() {
      (this.activators || []).forEach((a) => {
        const disposer = a();
        if (disposer) {
          this.disposers.push(disposer);
        }
      });
      super.activate();
    }
    deactivate() {
      this.disposers.forEach((d) => d());
      this.disposers.splice(0);
    }
    remove() {
      this.deactivate();
      super.remove();
    }
  }

  return CustomTool;
}

function renderItems<
  S extends PolygonStoreInstance | LineStoreInstance,
  M = S extends PolygonStoreInstance ? PolygonModelInstance : LineModelInstance
>(
  scope: paper.PaperScope,
  scale: number,
  store: S,
  itemsExpression: () => Array<M>,
  renderFunction: (scope: paper.PaperScope, model: M, scale: number) => () => void
) {
  const map = new Map<M, () => void>();
  const disposer = addDisposer(
    store,
    autorun(() => {
      itemsExpression().forEach((model) => {
        if (!map.has(model)) {
          addDisposer(model, () => {
            map.delete(model);
          });
          map.set(model, addDisposer(model, renderFunction(scope, model, scale)));
        }
      });
    })
  );
  return () => {
    [...map.values(), disposer].forEach((d) => d());
  };
}
