const FONT_SIZE = 14;
const BACKGROUND_PADDING = 5;
const BACKGROUND_BORDER_RADIUS = 5;
const BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.3)';

export interface ITooltip extends paper.Group {
  data: {
    readonly text: paper.PointText;
    readonly background: paper.Shape.Rectangle;
  };
}

export function renderTooltip(
  scope: paper.PaperScope,
  scale: number,
  point: paper.Point,
  text: string,
  justification = 'left',
  position = 'center'
): ITooltip {
  const tooltipText = new scope.PointText(point);
  tooltipText.content = text;
  tooltipText.fillColor = new scope.Color('white');
  tooltipText.fontSize = FONT_SIZE / scale;
  tooltipText.justification = justification;
  if (position === 'bottom') {
    tooltipText.position = tooltipText.position.add(new scope.Point([0, tooltipText.bounds.height + 2]));
  }
  const tooltipBackground = new scope.Shape.Rectangle(
    addPadding(scope, tooltipText.bounds, BACKGROUND_PADDING / scale),
    new scope.Size(BACKGROUND_BORDER_RADIUS / scale, BACKGROUND_BORDER_RADIUS / scale)
  );
  tooltipBackground.fillColor = new scope.Color(BACKGROUND_COLOR);
  const group = new scope.Group([tooltipBackground, tooltipText]);
  group.locked = true;
  if (text === '') {
    group.visible = false;
  }
  group.data.text = tooltipText;
  group.data.background = tooltipBackground;

  fitView(scope, group);

  return group;
}

function fitView(scope: paper.PaperScope, group: paper.Group) {
  const fitByAxis = (propName1: 'left' | 'top', propName2: 'right' | 'bottom', size: number) => {
    if (group.bounds[propName1] < scope.view.bounds[propName1]) {
      group.bounds[propName1] = scope.view.bounds[propName1];
      group.bounds[propName2] = group.bounds[propName1] + size;
    }
    if (group.bounds[propName2] > scope.view.bounds[propName2]) {
      group.bounds[propName2] = scope.view.bounds[propName2];
      group.bounds[propName1] = group.bounds[propName2] - size;
    }
  };
  fitByAxis('left', 'right', group.bounds.width);
  fitByAxis('top', 'bottom', group.bounds.height);
}

function addPadding(scope: paper.PaperScope, original: paper.Rectangle, padding: number) {
  return new scope.Rectangle(
    original.left - padding,
    original.top - padding,
    original.width + padding * 2,
    original.height + padding * 2
  );
}
