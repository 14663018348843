import {ToolName} from './ToolName';
import {StoreInstance} from '../Store';
import {ILine, isLine} from '../items/Line';
import {autorun, observable} from 'mobx';
import {ICustomToolClass} from '../PaperProject';
import {addDisposer} from 'mobx-state-tree';
import {checkPathBounds, createToolHandle, isInsideDecorator, IToolHandle} from './utils';

export function createMoveLineTool(
  CustomTool: ICustomToolClass,
  scope: paper.PaperScope,
  store: StoreInstance,
  scale: number
) {
  const itemIsStartHandle = (item: paper.Item | null) => {
    return !!item && item === startHandle;
  };
  const isEndHandle = (item: paper.Item | null) => {
    return !!item && item === endHandle;
  };
  const itemIsHandle = (item: paper.Item | null) => {
    return itemIsStartHandle(item) || isEndHandle(item);
  };
  const itemIsLine = (item: paper.Item | null): item is ILine => {
    return !!item && isLine(item);
  };
  const itemIsMovableLine = (item: paper.Item | null): item is ILine => {
    return itemIsLine(item) && item.data.model.editable;
  };
  const itemIsMovable = (item: paper.Item | null) => {
    return itemIsLine(item) || itemIsHandle(item);
  };

  const startHandle = createToolHandle(scope, scale);
  const endHandle = createToolHandle(scope, scale);
  const showHandles = () => {
    [startHandle, endHandle].forEach((handle) => {
      handle.visible = true;
      handle.locked = false;
      handle.bringToFront();
    });
  };
  const hideHandles = () => {
    [startHandle, endHandle].forEach((handle) => {
      handle.visible = false;
      handle.locked = true;
    });
  };

  type SelectedLine = ILine | null;
  const selectedLineBox = observable.box<SelectedLine>(null);
  type HighlightedItem = ILine | IToolHandle | null;
  const highlightedItemBox = observable.box<HighlightedItem>(null);

  const autoCursor = store.createCursorHandler('auto');
  const grabCursor = store.createCursorHandler('grab');
  const grabbingCursor = store.createCursorHandler('grabbing');

  const selectDisposers: Array<() => void> = [];
  const applySelection = (newValue: SelectedLine, oldValue?: SelectedLine) => {
    hideHandles();
    selectDisposers.forEach((d) => d());
    selectDisposers.splice(0);
    if (newValue) {
      const line = newValue as ILine;
      line.selected = false;
      showHandles();
      selectDisposers.push(
        autorun(() => {
          const {x, y} = line.data.model.start;
          startHandle.position.set(x, y);
        })
      );
      selectDisposers.push(
        autorun(() => {
          const {x, y} = line.data.model.end;
          endHandle.position.set(x, y);
        })
      );
      selectDisposers.push(
        addDisposer(line.data.model, () => {
          if (selectedLineBox.get()?.data.model === line.data.model) {
            selectedLineBox.set(null);
          }
        })
      );
      store.setSelectedLine(line.data.model);
    } else {
      store.setSelectedLine(undefined);
    }
  };

  const applyHighlight = (newValue: HighlightedItem, oldValue?: HighlightedItem) => {
    if (oldValue) {
      const highlightedItem = oldValue;
      if (itemIsHandle(highlightedItem)) {
        const handle = highlightedItem as IToolHandle;
        handle.data.highlighted = false;
      } else if (itemIsLine(highlightedItem)) {
        const line = highlightedItem as ILine;
        line.data.line.selected = false;
      }
    }
    if (newValue) {
      const highlightedItem = newValue;
      if (itemIsHandle(highlightedItem)) {
        const handle = highlightedItem as IToolHandle;
        handle.data.highlighted = true;
      } else if (itemIsMovableLine(highlightedItem)) {
        const line = highlightedItem;
        if (line !== selectedLineBox.get()) {
          line.data.line.selected = true;
        }
      }
    }
  };

  const cleanUp = () => {
    highlightedItemBox.set(null);
    selectedLineBox.set(null);
  };

  const tool = new CustomTool(ToolName.moveLine, [
    () => {
      autoCursor.obtain();
      return () => {
        autoCursor.release();
      };
    },
    () => {
      return cleanUp;
    },
    () => {
      return selectedLineBox.observe(({newValue, oldValue}) => {
        applySelection(newValue, oldValue);
      });
    },
    () => {
      return highlightedItemBox.observe(({newValue, oldValue}) => {
        applyHighlight(newValue, oldValue);
      });
    }
  ]);

  let heldItem: IToolHandle | ILine | null = null;
  tool.onMouseDown = isInsideDecorator(scope)((e) => {
    const selectedItem = selectedLineBox.get();
    if (e.item !== selectedItem) {
      if (itemIsMovableLine(e.item)) {
        const lineGroup = e.item as ILine;
        selectedLineBox.set(lineGroup);
      } else if (itemIsHandle(e.item)) {
        heldItem = e.item as IToolHandle;
      } else {
        selectedLineBox.set(null);
      }
    }
    if (itemIsMovable(e.item)) {
      grabbingCursor.obtain();
      heldItem = e.item as ILine | IToolHandle;
    }
  });

  tool.onMouseUp = isInsideDecorator(scope)((e) => {
    const selectedItem = selectedLineBox.get();
    if (selectedItem && heldItem === selectedItem) {
      const {
        data: {line, model}
      } = selectedItem as ILine;
      const {x: x1, y: y1} = line.firstSegment.point;
      const {x: x2, y: y2} = line.lastSegment.point;
      model.move(x1, y1, x2, y2);
    } else if (selectedItem && heldItem === startHandle) {
      const {
        data: {line, model}
      } = selectedItem as ILine;
      const {x, y} = line.firstSegment.point;
      model.moveStart(x, y);
    } else if (selectedItem && heldItem === endHandle) {
      const {
        data: {line, model}
      } = selectedItem as ILine;
      const {x, y} = line.lastSegment.point;
      model.moveEnd(x, y);
    }

    heldItem = null;

    grabbingCursor.release();
  });

  tool.onMouseMove = isInsideDecorator(scope)((e) => {
    const highlightedItem = highlightedItemBox.get();
    if (e.item !== highlightedItem) {
      if (itemIsMovable(e.item)) {
        highlightedItemBox.set(e.item as paper.Path);
        grabCursor.obtain();
      } else {
        grabCursor.release();
        highlightedItemBox.set(null);
      }
    }
  });

  tool.onMouseDrag = isInsideDecorator(scope)((e) => {
    if (heldItem) {
      const selectedItem = selectedLineBox.get();
      if (selectedItem === heldItem) {
        const {
          data: {line}
        } = heldItem as ILine;
        if (checkPathBounds(scope, line, e.delta)) {
          heldItem.translate(e.delta);
          startHandle.position.set(line.firstSegment.point);
          endHandle.position.set(line.lastSegment.point);
        }
      } else if (selectedItem && itemIsHandle(heldItem)) {
        const handle = heldItem as IToolHandle;
        const {
          data: {line}
        } = selectedItem as ILine;
        handle.position.set(handle.position.add(e.delta));
        if (itemIsStartHandle(handle)) {
          line.firstSegment.point.set(handle.position);
        }
        if (isEndHandle(handle)) {
          line.lastSegment.point.set(handle.position);
        }
      }
    }
  });

  // tool.onKeyDown = ({event}: {event: KeyboardEvent}) => {
  //   // if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyZ' || event.key === 'z')) {
  //   //   if (event.shiftKey) {
  //   //     store.polygonStore.nextState();
  //   //   } else {
  //   //     store.polygonStore.prevState();
  //   //   }
  //   //   return;
  //   // }
  //   if (event.code === 'Escape') {
  //     selectedLineBox.set(null);
  //   }
  //   if (event.code === 'Delete' || event.code === 'Backspace') {
  //     if (store.selectedLine) {
  //       store.itemStore.lineStore.removeLine(store.selectedLine);
  //     }
  //   }
  // };

  return tool;
}
