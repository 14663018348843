import {addDisposer, destroy, Instance, types} from 'mobx-state-tree';
import {SegmentModel, StoreInstance} from '../Store';
import {ICustomToolClass} from '../PaperProject';
import {ToolName} from './ToolName';
import {autorun} from 'mobx';
import paper from 'paper';

enum Status {
  empty = 'empty',
  point = 'onePoint',
  vector = 'vector'
}

const ToolStore = types
  .model({
    start: types.maybeNull(SegmentModel),
    end: types.maybeNull(SegmentModel)
  })
  .views((self) => {
    return {
      get status() {
        if (self.start === null && self.end === null) {
          return Status.empty;
        }
        if (self.start && self.end === null) {
          return Status.point;
        }
        if (self.start && self.end) {
          return Status.vector;
        }
        throw Error('Invalid status');
      }
    };
  })
  .views((self) => {
    return {
      validate(expected: Status) {
        return self.status === expected;
      }
    };
  })
  .views((self) => {
    return {
      get canSetStart() {
        return self.validate(Status.empty);
      },
      get canSetEnd() {
        return self.validate(Status.point);
      }
    };
  })
  .actions((self) => {
    return {
      setStart(x: number, y: number) {
        if (self.canSetStart) {
          self.start = SegmentModel.create({
            index: 0,
            x,
            y
          });
        } else {
          throw Error(`Current status (${self.status}) does not support .setStart()`);
        }
      },
      setEnd(x: number, y: number) {
        if (self.canSetEnd) {
          self.end = SegmentModel.create({
            index: 1,
            x,
            y
          });
        } else {
          throw Error(`Current status (${self.status}) does not support .setEnd()`);
        }
      },
      reset() {
        if (self.start) {
          destroy(self.start);
          self.start = null;
        }
        if (self.end) {
          destroy(self.end);
          self.end = null;
        }
      }
    };
  });

const handleRadius = 10;
const handleFillColor = 'white';
const handleStrokeColor = 'black';
const handleStrokeWidth = 1;

const lineStrokeWidth = 2;
const lineStrokeColor = 'crimson';

function renderHandle(x: number, y: number, scope: paper.PaperScope, scale: number) {
  const item = new scope.Path.Circle(new scope.Point(x, y), handleRadius / scale);
  item.fillColor = new scope.Color(handleFillColor);
  item.strokeColor = new scope.Color(handleStrokeColor);
  item.strokeWidth = handleStrokeWidth / scale;
  return item;
}

function renderLine(start: paper.Point, end: paper.Point, scope: paper.PaperScope, scale: number) {
  const item = new scope.Path([start, end]);
  item.strokeColor = new scope.Color(lineStrokeColor);
  item.strokeWidth = lineStrokeWidth / scale;
  return item;
}

export function createAddVectorTool(
  CustomTool: ICustomToolClass,
  scope: paper.PaperScope,
  store: StoreInstance,
  scale: number
) {
  class AddVectorTool extends CustomTool {
    public model: Instance<typeof ToolStore> = null!;
  }

  const copyCursor = store.createCursorHandler('copy');

  const tool = new AddVectorTool(ToolName.addLine, [
    () => {
      copyCursor.obtain();
      return () => {
        copyCursor.release();
      };
    }
  ]);
  tool.model = ToolStore.create();

  let startHandle: paper.Path | null = null;
  let endHandle: paper.Path | null = null;
  let line: paper.Path | null = null;

  addDisposer(
    tool.model,
    autorun(() => {
      switch (tool.model.status) {
        case Status.empty:
          {
            if (startHandle) {
              startHandle.remove();
              startHandle = null;
            }
            if (endHandle) {
              endHandle.remove();
              endHandle = null;
            }
            if (line) {
              line.remove();
            }
          }
          return;
        case Status.point:
          {
            if (tool.model.start) {
              const {x, y} = tool.model.start;
              if (startHandle) {
                startHandle.position.set(x, y);
              } else {
                startHandle = renderHandle(x, y, scope, scale);
              }
            }
          }
          return;
        case Status.vector:
          {
            if (tool.model.start && tool.model.end) {
              const {x: x1, y: y1} = tool.model.start;
              const {x: x2, y: y2} = tool.model.end;
              if (startHandle) {
                startHandle.position.set(x1, y1);
              } else {
                startHandle = renderHandle(x1, y1, scope, scale);
              }
              if (endHandle) {
                endHandle.position.set(x2, y2);
              } else {
                endHandle = renderHandle(x2, y2, scope, scale);
              }
              if (line) {
                line.firstSegment.point.set(startHandle.position);
                line.lastSegment.point.set(endHandle.position);
              } else {
                line = renderLine(startHandle.position, endHandle.position, scope, scale);
              }
            }
          }
          return;
      }
    })
  );

  addDisposer(
    tool.model,
    autorun(() => {
      if (tool.model.validate(Status.vector)) {
        const {start, end} = tool.model;
        if (start && end) {
          store.itemStore.lineStore.addLine([start.x, start.y], [end.x, end.y]);
          tool.model.reset();
          store.selectTool(ToolName.moveLine);
        }
      }
    })
  );

  tool.onMouseDown = (e: paper.ToolEvent) => {
    switch (tool.model.status) {
      case Status.empty:
        {
          tool.model.setStart(e.point.x, e.point.y);
        }
        return;
      case Status.point:
        {
          tool.model.setEnd(e.point.x, e.point.y);
        }
        return;
      case Status.vector:
        {
        }
        return;
    }
  };

  tool.onMouseMove = (e: paper.ToolEvent) => {
    switch (tool.model.status) {
      case Status.empty:
        {
        }
        return;
      case Status.point:
        {
          if (startHandle) {
            const tempLine = new scope.Path([startHandle.position, e.point]);
            tempLine.strokeWidth = 4;
            tempLine.strokeColor = new scope.Color('crimson');
            tempLine.removeOnMove();
            startHandle.bringToFront();
          }
        }
        return;
      case Status.vector:
        {
        }
        return;
    }
  };

  return tool;
}
