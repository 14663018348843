import React, {useContext, useEffect, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {Store, StoreInstance} from './Store';
import Canvas, {CanvasSize} from './Canvas';
import {ToolName} from './tools/ToolName';
import {applySnapshot, destroy, getSnapshot, onSnapshot} from 'mobx-state-tree';
import {defaultLocale} from './Locale';
import {calcCanvasSize, useMountedRef} from './utils';
import {ControlsParams, IWidgetProps, OverlayParams, WidgetBaseProps} from './IWidgetProps';

const LocaleContext = React.createContext(defaultLocale);

export function useLocale() {
  return useContext(LocaleContext);
}

export default function Root({props}: IWidgetProps) {
  const {
    overlay,
    controls,
    initState = {
      activeTool: ToolName.movePolygon,
      itemStore: {
        polygonStore: {},
        lineStore: {}
      }
    },
    currentSnapshot,
    onUpdate
  } = props;
  return (
    <React.StrictMode>
      <LocaleContext.Provider value={defaultLocale}>
        {overlay ? (
          <Portal
            initState={initState}
            currentSnapshot={currentSnapshot}
            onUpdate={onUpdate}
            overlay={overlay}
            controls={controls}
          />
        ) : null}
      </LocaleContext.Provider>
    </React.StrictMode>
  );
}

interface PortalsProps {
  initState: WidgetBaseProps['initState'];
  currentSnapshot: WidgetBaseProps['currentSnapshot'];
  onUpdate: WidgetBaseProps['onUpdate'];
  overlay: OverlayParams;
  controls?: ControlsParams;
}

function Portal({initState, onUpdate, overlay, controls, currentSnapshot}: PortalsProps) {
  const store = useStore(initState, onUpdate, currentSnapshot);
  const canvasSize = useCanvasSize(overlay);
  return store && canvasSize ? (
    <Canvas
      store={store}
      size={canvasSize}
      controlsContainer={controls?.controlsContainer}
      tooltipPosition={controls?.tooltipPosition ?? 'bottom'}
    />
  ) : null;
}

function useCanvasSize(overlay: OverlayParams) {
  const mountedRef = useMountedRef();
  const [canvasSize, setCanvasSize] = useState<CanvasSize | null>(null);
  useEffect(() => {
    if (overlay) {
      const {overlayBase, naturalWidth, naturalHeight} = overlay;
      let timeout: number | null = null;
      const updateCanvasProps = () => {
        if (typeof timeout === 'number') {
          clearTimeout(timeout);
        }
        timeout = window.setTimeout(() => {
          if (mountedRef.current) {
            setCanvasSize(
              calcCanvasSize(overlayBase.clientHeight, overlayBase.clientWidth, naturalHeight, naturalWidth)
            );
          }
          timeout = null;
        }, 500);
      };
      updateCanvasProps();
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === overlayBase) {
            requestAnimationFrame(() => {
              updateCanvasProps();
            });
          }
        }
      });
      resizeObserver.observe(overlayBase);

      return () => {
        resizeObserver.disconnect();
        setCanvasSize(null);
      };
    }
    return undefined;
  }, [mountedRef, overlay]);
  return canvasSize;
}

function useStore(
  initState: WidgetBaseProps['initState'],
  onUpdate: WidgetBaseProps['onUpdate'],
  currentSnapshot: WidgetBaseProps['currentSnapshot']
) {
  const [store, setStore] = useState<StoreInstance | null>(null);
  // init new store, when initState changes
  useEffect(() => {
    setStore(Store.create(initState));
  }, [initState]);
  // send update when itemStore changes
  useEffect(() => {
    if (store && typeof onUpdate === 'function') {
      onUpdate(getSnapshot(store.itemStore));
      return onSnapshot(store.itemStore, (snapshot) => {
        onUpdate(snapshot);
      });
    }
    return undefined;
  }, [store, onUpdate]);
  // update store, when currentSnapshot is changed from outside
  useEffect(() => {
    if (store && currentSnapshot) {
      applySnapshot(store.itemStore, currentSnapshot);
    }
  }, [store, currentSnapshot]);
  // destroy old store, when it is changed
  useEffect(() => {
    if (store) {
      return () => {
        destroy(store);
      };
    }
    return undefined;
  }, [store]);
  return store;
}
