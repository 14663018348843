import {StoreInstance} from '../Store';
import {ToolName} from './ToolName';
import {ICustomToolClass} from '../PaperProject';
import {renderTooltip} from '../items/Tooltip';
import {Locale} from '../Locale';

const POINT_RADIUS = 10;
const DEFAULT_STROKE_COLOR = 'rgba(255, 60, 60, 1)';

export function createAddTool(
  CustomTool: ICustomToolClass,
  scope: paper.PaperScope,
  store: StoreInstance,
  scale: number,
  locale: Locale
) {
  const copyCursor = store.createCursorHandler('copy');

  class PathPoint extends scope.Path.Circle {
    constructor(public segment: paper.Segment, radius: number = POINT_RADIUS / scale) {
      super(segment.point, radius);
      this.data.type = PathPoint;
      this.fillColor = new scope.Color('white');
      this.strokeColor = new scope.Color('black');
      this.strokeWidth = 1 / scale;
    }
  }

  let points: Array<PathPoint> | null = null;
  let path: paper.Path | null = null;

  const onCancel = () => {
    points?.forEach((p) => {
      p.remove();
    });
    path?.remove();
    points = null;
    path = null;
  };

  const onAddPolygon = () => {
    if (path) {
      store.itemStore.polygonStore.addPolygon(path.segments.map(({point: {x, y}}) => ({x, y})));
      onCancel();
      store.selectTool(ToolName.movePolygon);
    }
  };

  const addPoint = (point: paper.Point) => {
    if (path && points) {
      const segment = path.add(point) as paper.Segment;
      points.push(new PathPoint(segment));
      points[0].bringToFront();
    }
  };

  const itemIsTheFirstPoint = (item: paper.Item | null | undefined) => {
    if (item?.data?.type === PathPoint) {
      const point = item as PathPoint;
      if (point.segment.index === 0) {
        return true;
      }
    }
    return false;
  };

  const tool = new CustomTool(ToolName.addPolygon, [
    () => {
      copyCursor.obtain();
      return () => {
        copyCursor.release();
      };
    },
    () => {
      return onCancel;
    }
  ]);

  let latestItem: paper.Item | null = null;
  let releasePointerCursor = () => {};
  tool.onMouseMove = (event: paper.ToolEvent) => {
    if (event.item !== latestItem) {
      if (itemIsTheFirstPoint(event.item)) {
        releasePointerCursor = store.obtainCursor((points?.length || 0) > 2 ? 'pointer' : 'no-drop');
      } else {
        releasePointerCursor();
      }
    }
    latestItem = event.item;
    if (path) {
      const tmpPath = new scope.Path([
        path.lastSegment,
        new scope.Segment(event.point.subtract(new scope.Point([1, 1])))
      ]);
      tmpPath.sendToBack();
      tmpPath.strokeColor = new scope.Color(DEFAULT_STROKE_COLOR);
      path.strokeWidth = 2 / scale;
      tmpPath.removeOnMove();

      const tooltipText =
        path.segments.length > 2 ? locale.addPolygon.finishSelection : locale.addPolygon.addMorePoints;
      const tooltip = renderTooltip(scope, scale, event.point.add(new scope.Point(100, 100)), tooltipText);
      tooltip.removeOnMove();
    }
  };

  tool.onMouseDown = (event: paper.ToolEvent) => {
    if ((path && !points) || (!path && points)) {
      throw Error('invalid state');
    }
    if (!points) {
      path = new scope.Path();
      path.strokeColor = new scope.Color(DEFAULT_STROKE_COLOR);
      path.strokeWidth = 2 / scale;
      path.fillColor = new scope.Color(path.strokeColor);
      path.fillColor.alpha = 0.15;
      const segment = path.add(event.point) as paper.Segment;
      const pathPoint = new PathPoint(segment);
      pathPoint.fillColor = new scope.Color('white');
      pathPoint.strokeColor = new scope.Color('blue');
      pathPoint.strokeWidth = 3 / scale;
      points = [pathPoint];
    } else if (path && points.length > 2) {
      const {item} = event;
      if (itemIsTheFirstPoint(item)) {
        onAddPolygon();
      } else {
        addPoint(event.point);
      }
    } else {
      const {item} = event;
      if (itemIsTheFirstPoint(item)) {
      } else {
        addPoint(event.point);
      }
    }
  };

  // tool.onKeyDown = ({event}) => {
  //   if (event.code === 'Escape') {
  //     onCancel();
  //   } else if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyZ' || event.key === 'z')) {
  //     if (points && path) {
  //       if (!event.shiftKey) {
  //         if (points.length <= 1) {
  //           onCancel();
  //         } else {
  //           const p = points.pop() as PathPoint;
  //           p.remove();
  //           path.lastSegment.remove();
  //         }
  //       }
  //     }
  //   }
  // };

  return tool;
}
