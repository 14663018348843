export type Locale = typeof defaultLocale;

export const defaultLocale = {
  addPolygon: {
    addMorePoints: 'Зона должна содержать минимум 3 точки',
    finishSelection: 'Для завершения выделения\nкликните на первую точку'
  },
  controls: {
    moveArea: 'Переместить',
    moveLine: 'Переместить',
    addArea: 'Добавить',
    addLine: 'Добавить',
    undo: 'Отменить',
    redo: 'Повторить',
    delete: 'Удалить',
    addDirection: 'Добавить направление',
    removeDirection: 'Убрать направление',
    flip: 'Сменить направление',
    bothDirections: 'В обе стороны',
    oneDirection: 'В одну стороны',
    save: 'Сохранить'
  }
};
