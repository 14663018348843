import {IPolygon, isPolygon} from '../items/Polygon';
import {StoreInstance} from '../Store';
import {ToolName} from './ToolName';
import {autorun} from 'mobx';
import {addDisposer, isAlive} from 'mobx-state-tree';
import {ICustomToolClass} from '../PaperProject';
import {checkPathBounds, isInsideDecorator} from './utils';
import {createDisposers, IDisposers} from '../utils/disposers';

const HANDLE_RADIUS = 10;
const ARROW_HANDLE_RADIUS = 5;

export function createMoveTool(
  CustomTool: ICustomToolClass,
  scope: paper.PaperScope,
  store: StoreInstance,
  scale: number
) {
  class MoveHandle extends scope.Path.Circle {
    constructor(public segment: paper.Segment, radius: number = HANDLE_RADIUS / scale) {
      super(segment.point, radius);
      this.data.type = MoveHandle;
      this.fillColor = new scope.Color('white');
      this.strokeColor = new scope.Color('black');
      this.strokeWidth = 1 / scale;
    }
  }

  class ArrowHandle extends scope.Path.Circle {
    constructor(position: paper.Point, radius: number = ARROW_HANDLE_RADIUS / scale) {
      super(position, radius);
      this.data.type = ArrowHandle;
      this.fillColor = new scope.Color('white');
      this.strokeColor = new scope.Color('black');
      this.strokeWidth = 1 / scale;
    }
  }

  const itemIsHandle = (item: paper.Item | null): item is MoveHandle => {
    return !!item && item.data.type === MoveHandle;
  };
  const itemIsPolygon = (item: paper.Item | null): item is IPolygon => {
    return !!item && isPolygon(item);
  };
  const itemIsMovablePolygon = (item: paper.Item | null): item is IPolygon => {
    return itemIsPolygon(item) && item.data.model.editable;
  };
  const itemIsArrowHandle = (item: paper.Item | null): item is ArrowHandle => {
    return !!item && item.data.type === ArrowHandle;
  };
  const itemIsMovable = (item: paper.Item | null): item is IPolygon | MoveHandle | ArrowHandle => {
    return itemIsHandle(item) || itemIsMovablePolygon(item) || itemIsArrowHandle(item);
  };

  let selectedItem: IPolygon | null = null;
  let disposers: IDisposers | null = null;
  let handles: Array<MoveHandle> | null = null;
  let arrowHandle: ArrowHandle | null = null;
  let currentTarget: IPolygon | MoveHandle | ArrowHandle | null = null;
  let highlightedItem: IPolygon | MoveHandle | ArrowHandle | null = null;

  const autoCursor = store.createCursorHandler('auto');
  const grabCursor = store.createCursorHandler('grab');
  const grabbingCursor = store.createCursorHandler('grabbing');

  const applySelection = (_selectedItem: IPolygon) => {
    _selectedItem.data.polygon.selected = false;

    disposers = createDisposers();

    store.setSelectedPolygon(_selectedItem.data.model);

    const _handles = _selectedItem.data.polygon.segments.map((segment) => new MoveHandle(segment));

    const _arrowHandle = new ArrowHandle(_selectedItem.data.polygon.firstSegment.point);

    disposers.add(
      autorun(() => {
        _selectedItem.data.model.points.map(({x, y, index}) => {
          const h = _handles[index];
          if (h) {
            h.position.set([x, y]);
          }
        });
        if (
          typeof _selectedItem.data.model.direction?.x === 'number' &&
          typeof _selectedItem.data.model.direction?.y === 'number'
        ) {
          const position = _selectedItem.data.arrow.data.end;
          _arrowHandle.position.set(position);
          _arrowHandle.locked = false;
          _arrowHandle.visible = true;
        } else {
          _arrowHandle.locked = true;
          _arrowHandle.visible = false;
        }
      })
    );

    disposers.add(
      addDisposer(_selectedItem.data.model, () => {
        removeSelection();
      })
    );

    selectedItem = _selectedItem;
    handles = _handles;
    arrowHandle = _arrowHandle;
  };

  const removeSelection = () => {
    if (selectedItem) {
      selectedItem.selected = false;
      selectedItem = null;
    }
    if (isAlive(store) && store.selectedPolygon) {
      store.setSelectedPolygon(undefined);
    }
    if (handles) {
      handles.forEach((h) => h.remove());
      handles = null;
    }
    if (arrowHandle) {
      arrowHandle.remove();
      arrowHandle = null;
    }
    if (disposers) {
      disposers.flush();
      disposers = null;
    }
  };

  const tool = new CustomTool(ToolName.movePolygon, [
    () => {
      autoCursor.obtain();
      return () => {
        autoCursor.release();
      };
    },
    () => {
      return removeSelection;
    }
  ]);

  tool.onMouseMove = isInsideDecorator(scope)((e) => {
    // react only when current item changes
    if (highlightedItem !== e.item) {
      // if there is a prevItem, clean is necessary
      if (highlightedItem) {
        // onLeave
        if (itemIsHandle(highlightedItem) || itemIsArrowHandle(highlightedItem)) {
          highlightedItem.fillColor = new scope.Color('white');
        }
        if (itemIsMovablePolygon(highlightedItem)) {
          highlightedItem.data.polygon.selected = false;
        }
        highlightedItem = null;
        grabCursor.release();
      }
      // if there is a new item, it becomes new highlighted item
      if (e.item) {
        // onEnter
        if (itemIsHandle(e.item) || itemIsArrowHandle(e.item)) {
          grabCursor.obtain();
          highlightedItem = e.item;
          highlightedItem.fillColor = new scope.Color('lightblue');
        }
        if (itemIsMovablePolygon(e.item)) {
          grabCursor.obtain();
          highlightedItem = e.item;
          if (highlightedItem !== selectedItem) {
            highlightedItem.data.polygon.selected = true;
          }
        }
      }
    }
  });

  tool.onMouseDown = isInsideDecorator(scope)((e: paper.ToolEvent) => {
    // current dragging target is fixed here, in order to prevent onDrag bugs, when mouse moves faster than target

    // change cursor on targets, that cam moved
    if (itemIsMovable(e.item)) {
      grabbingCursor.obtain();
      currentTarget = e.item;
    }
    // manage targets selections on click
    if (itemIsMovablePolygon(e.item)) {
      if (selectedItem) {
        if (selectedItem !== e.item) {
          removeSelection();
          applySelection(e.item);
        }
      } else {
        applySelection(e.item);
      }
    } else if (itemIsHandle(e.item) || itemIsArrowHandle(e.item)) {
    } else {
      if (selectedItem) {
        removeSelection();
      }
    }
  });

  tool.onMouseUp = () => {
    grabbingCursor.release();
    if (currentTarget) {
      // onMouseUp propagate changes to store
      if (itemIsHandle(currentTarget)) {
        const handle = currentTarget as MoveHandle;
        const index = handle.segment.index;
        if (selectedItem) {
          selectedItem.data.model.movePoint(index, handle.position.x, handle.position.y);
        }
      } else if (itemIsArrowHandle(currentTarget)) {
        if (selectedItem) {
          const arrow = selectedItem.data.arrow;
          const mainPath = arrow.children[1] as paper.Path;
          const point = mainPath.lastSegment.point
            .subtract(mainPath.firstSegment.point)
            .rotate(0, new scope.Point([0, 0]));
          point.length = 1;
          selectedItem.data.model.setDirection([point.x, point.y]);
        }
      } else if (itemIsMovablePolygon(currentTarget)) {
        const polygonGroup = currentTarget;
        const polygon = polygonGroup.data.polygon;
        polygonGroup.data.model.move(polygon.segments.map((s) => [s.point.x, s.point.y]));
      }
    }
    currentTarget = null;
  };

  tool.onMouseDrag = isInsideDecorator(scope)((e: paper.ToolEvent) => {
    // happens only on movable targets
    if (currentTarget) {
      if (itemIsHandle(currentTarget)) {
        const handle = currentTarget;
        const {point: newPosition} = e;
        handle.position.set(newPosition);
        handle.segment.point.set(newPosition);
      } else if (itemIsArrowHandle(currentTarget)) {
        if (selectedItem) {
          const {point} = e;
          const arrow = selectedItem.data.arrow;
          const secondArrow = selectedItem.data.secondArrow;
          const {angle: newAngle} = point.subtract(arrow.data.start);
          arrow.data.rotate(newAngle);
          secondArrow.data.rotate(newAngle + 180);
          currentTarget.position.set(arrow.data.end);
        }
      } else if (itemIsMovablePolygon(currentTarget)) {
        const polygonGroup = currentTarget;
        const {delta} = e;
        const polygon = polygonGroup.data.polygon;
        if (checkPathBounds(scope, polygon, delta)) {
          polygonGroup.translate(delta);
          handles?.forEach((h, i) => {
            h.position.set(polygon.segments[i].point);
          });
          if (arrowHandle) {
            const position = polygonGroup.data.arrow.data.end;
            arrowHandle?.position.set(position);
          }
        }
      }
    }
  });

  // tool.onKeyDown = ({event}: {event: KeyboardEvent}) => {
  //   // if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyZ' || event.key === 'z')) {
  //   //   if (event.shiftKey) {
  //   //     store.polygonStore.nextState();
  //   //   } else {
  //   //     store.polygonStore.prevState();
  //   //   }
  //   //   return;
  //   // }
  //   if (event.code === 'Escape') {
  //     removeSelection();
  //   }
  //   if (event.code === 'Delete' || event.code === 'Backspace') {
  //     if (selectedItem) {
  //       store.itemStore.polygonStore.removePolygon(selectedItem.data.model);
  //     }
  //   }
  // };

  return tool;
}
