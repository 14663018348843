import {autorun, reaction} from 'mobx';
import {LineModelInstance} from '../Store';
import {ItemType} from './ItemType';
import {ITooltip, renderTooltip} from './Tooltip';
import {renderArrow} from './Arrow';
import {createDisposers} from '../utils/disposers';

const DEFAULT_STROKE_WIDTH = 3;
const DEFAULT_STROKE_COLOR = 'rgba(255, 60, 60, 1)';

export function isLine(item: paper.Item) {
  return item.data.type === ItemType.line;
}

export interface ILine extends paper.Group {
  data: {
    type: ItemType.line;
    arrowLeft: paper.Path;
    arrowRight: paper.Path;
    model: LineModelInstance;
    line: paper.Path.Line;
    tooltip: ITooltip;
  };
}

export function renderLine(scope: paper.PaperScope, model: LineModelInstance, scale: number) {
  const context = {scope, scale};
  const disposers = createDisposers();

  const start = new scope.Point(model.start.x, model.start.y);
  const end = new scope.Point(model.end.x, model.end.y);

  const customStyle = model.style as Partial<paper.Style>;
  const line = new scope.Path.Line(start, end);
  line.strokeColor = customStyle.strokeColor ?? new scope.Color(DEFAULT_STROKE_COLOR);
  line.strokeWidth = DEFAULT_STROKE_WIDTH / scale;

  let tooltip = renderTooltip(scope, scale, line.bounds.center, model.name);

  const toRightArrow = renderArrow(context);
  toRightArrow.strokeColor = line.strokeColor;
  toRightArrow.strokeColor.hue += 120;
  toRightArrow.strokeWidth = line.strokeWidth;

  const toLeftArrow = renderArrow(context);
  toLeftArrow.strokeColor = line.strokeColor;
  toLeftArrow.strokeWidth = line.strokeWidth;
  toLeftArrow.strokeColor.hue += 120;
  toLeftArrow.dashArray = [toLeftArrow.strokeWidth / 2, (toLeftArrow.strokeWidth * 20) / 12];
  toLeftArrow.strokeCap = 'round';

  const lineGroup = new scope.Group([toRightArrow, toLeftArrow, line, tooltip]);

  lineGroup.data = {
    type: ItemType.line,
    model,
    line,
    tooltip,
    get arrowRight() {
      return toRightArrow;
    },
    get arrowLeft() {
      return toLeftArrow;
    }
  };

  disposers.add(
    autorun(() => {
      const [x1, y1, x2, y2] = [model.start.x, model.start.y, model.end.x, model.end.y];
      const start = new scope.Point(x1, y1);
      const end = new scope.Point(x2, y2);
      const rectangle = new scope.Rectangle(start, end);

      line.firstSegment.point.set(start);
      line.lastSegment.point.set(end);

      const {angle} = end.subtract(start);

      toRightArrow.data.setStart(line.bounds.center, 10 / scale);
      toRightArrow.data.rotate(angle + 90);

      toLeftArrow.data.setStart(line.bounds.center, 10 / scale);
      toLeftArrow.data.rotate(angle + 90 + 180);

      if (model.directed) {
        toLeftArrow.visible = false;
      } else {
        toLeftArrow.visible = true;
      }

      tooltip.remove();
      tooltip = renderTooltip(
        scope,
        scale,
        rectangle.bottomLeft.add(new scope.Point(30 / scale, 60 / scale)),
        model.name
      );
      lineGroup.addChild(tooltip);
    })
  );

  disposers.add(
    autorun(() => {
      const {editable} = model;
      line.locked = !editable;
    })
  );

  disposers.add(() => {
    line.remove();
    toRightArrow.remove();
    toLeftArrow.remove();
    tooltip.remove();
    lineGroup.remove();
  });

  return disposers.flush;
}
