export function isInsideDecorator(scope: paper.PaperScope) {
  return function _isInsideDecorator<T extends paper.ToolEvent>(func: (event: T) => void) {
    return (event: T) => {
      if (event.point.isInside(scope.view.bounds)) {
        func.call(undefined, event);
      }
    };
  };
}

export function checkPathBounds(scope: paper.PaperScope, path: paper.Path, delta: paper.Point) {
  return [
    path.bounds.topLeft.add(delta),
    path.bounds.topRight.add(delta),
    path.bounds.bottomRight.add(delta),
    path.bounds.bottomLeft.add(delta)
  ].every((point) => point.isInside(scope.view.bounds));
}

export interface IToolHandle extends paper.Path {
  data: ToolHandleData;
}

const handleSymbol = Symbol('handleSymbol');
interface ToolHandleData {
  [handleSymbol]: boolean;
  isToolHandle(item: paper.Item): boolean;
  highlighted: boolean;
}

const handleRadius = 10;
const handleFillColor = 'white';
const handleStrokeColor = 'black';
const handleStrokeWidth = 1;
const handleHighlightedColor = 'lightblue';

export function createToolHandle(scope: paper.PaperScope, scale: number): IToolHandle {
  const handle: IToolHandle = new scope.Path.Circle(new scope.Point(0, 0), handleRadius / scale);
  handle.fillColor = new scope.Color(handleFillColor);
  handle.strokeColor = new scope.Color(handleStrokeColor);
  handle.strokeWidth = handleStrokeWidth / scale;
  handle.visible = false;
  let highlighted = false;
  handle.data = {
    [handleSymbol]: true,
    isToolHandle(item: paper.Item) {
      return item.data[handleSymbol];
    },
    set highlighted(value: boolean) {
      if (value) {
        handle.fillColor = new scope.Color(handleHighlightedColor);
      } else {
        handle.fillColor = new scope.Color(handleFillColor);
      }
      highlighted = value;
    },
    get highlighted() {
      return highlighted;
    }
  };
  return handle;
}
