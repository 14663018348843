export interface IArrow extends paper.CompoundPath {
  data: {
    readonly start: paper.Point;
    readonly end: paper.Point;
    rotate: (angle: number) => void;
    setStart: (point: paper.Point, offset?: number) => void;
  };
}

const ARROW_STROKE_WIDTH = 4;
const ARROW_STROKE_COLOR = 'rgba(255, 60, 60, 1)';
const ARROW_BASE_SIZE = [20, 50];

export function renderArrow(context: {scope: paper.PaperScope; scale: number}): IArrow {
  const {scope, scale} = context;

  const [w, h] = ARROW_BASE_SIZE;
  const mapPoints = <T extends number[]>([x, y]: T) => {
    return new scope.Segment(new scope.Point(x / scale, y / scale));
  };
  const mainPath = new scope.Path(
    [
      [0, w / 2],
      [h, w / 2]
    ].map(mapPoints)
  );
  const arrow: IArrow = new scope.CompoundPath({
    children: [
      new scope.Path(
        [
          [(h / 20) * 12, w],
          [h, w / 2]
        ].map(mapPoints)
      ),
      mainPath,
      new scope.Path(
        [
          [(h / 20) * 12, 0],
          [h, w / 2]
        ].map(mapPoints)
      )
    ]
  });
  arrow.strokeColor = new scope.Color(ARROW_STROKE_COLOR);
  arrow.strokeWidth = ARROW_STROKE_WIDTH / scale;
  arrow.strokeJoin = 'round';
  let currentAngle = 0;
  let currentStart = new scope.Point(arrow.bounds.leftCenter);

  const setStart = (start: paper.Point) => {
    arrow.rotate(0 - currentAngle, currentStart);
    arrow.bounds.leftCenter.set(start);
    arrow.rotate(currentAngle, start);
    currentStart = start;
  };

  const rotate = (angle: number) => {
    arrow.rotate(angle - currentAngle, currentStart);
    currentAngle = angle;
  };

  arrow.data = {
    get start() {
      return currentStart;
    },
    get end() {
      return mainPath.lastSegment.point;
    },
    rotate,
    setStart
  };

  return arrow;
}
