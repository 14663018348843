type IPoint = {x: number; y: number};
export const calcCentroid = (pts: IPoint[]): IPoint => {
  const nPts = pts.length;
  const off = pts[0];
  let twiceArea = 0;
  let x = 0;
  let y = 0;
  let p1: IPoint, p2: IPoint;
  let f;
  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i];
    p2 = pts[j];
    f = (p1.x - off.x) * (p2.y - off.y) - (p2.x - off.x) * (p1.y - off.y);
    twiceArea += f;
    x += (p1.x + p2.x - 2 * off.x) * f;
    y += (p1.y + p2.y - 2 * off.y) * f;
  }
  f = twiceArea * 3;
  return {
    x: x / f + off.x,
    y: y / f + off.y
  };
};
