/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {StoreInstance} from './Store';
import {observer} from 'mobx-react-lite';
import {ToolName} from './tools/ToolName';
import React, {FC, useCallback} from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Button} from 'primereact/button';
import {useLocale} from './Root';

export interface ControlsProps {
  store: StoreInstance;
  tooltipPosition?: string;
}

// language=SCSS
const $container = css`
  & {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    background: transparent;
  }
  & > * {
    margin-right: var(--spacer-xs);
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

// language=SCSS
const $tool = css`
  & {
    display: flex;
    align-items: center;
  }
`;

// language=SCSS
const $mr = css`
  & {
    margin-right: var(--spacer-xs);
  }
`;

const Controls: FC<ControlsProps> = observer(({store, tooltipPosition}) => {
  const locale = useLocale().controls;
  const options = [];
  const lineLimit = store.itemStore.lineStore.editableLimit;
  if (lineLimit === null || lineLimit > 0) {
    options.push(
      {
        label: locale.moveLine,
        value: ToolName.moveLine,
        disabled: store.itemStore.lineStore.noItemsToEdit && ToolName.moveLine !== store.activeTool
      },
      {
        label: locale.addLine,
        value: ToolName.addLine,
        disabled: store.itemStore.lineStore.editableLimitIsReached
      }
    );
  }
  const polygonLimit = store.itemStore.polygonStore.editableLimit;
  if (polygonLimit === null || polygonLimit > 0) {
    options.push(
      {
        label: locale.moveArea,
        value: ToolName.movePolygon,
        disabled: store.itemStore.polygonStore.noItemsToEdit && ToolName.movePolygon !== store.activeTool
      },
      {
        label: locale.addArea,
        value: ToolName.addPolygon,
        disabled: store.itemStore.polygonStore.editableLimitIsReached
      }
    );
  }
  return (
    <div css={$container}>
      <SelectButton
        className={'p-highlighted'}
        itemTemplate={toolTemplate}
        value={store.activeTool}
        options={options}
        onChange={(e) => {
          if (e.value) {
            store.selectTool(e.value);
          }
        }}
      />
      {store.activeTool === ToolName.movePolygon && React.createElement(PolygonMoveControls, {store, tooltipPosition})}
      {store.activeTool === ToolName.moveLine && React.createElement(LineMoveControls, {store, tooltipPosition})}
    </div>
  );
});
export default Controls;

const toolIcons = {
  [ToolName.movePolygon]: 'mdi mdi-hand-right',
  [ToolName.addPolygon]: 'mdi mdi-plus',
  [ToolName.moveLine]: 'mdi mdi-hand-right',
  [ToolName.addLine]: 'mdi mdi-plus'
};

const toolTemplate = (props: {label: string; value: ToolName; disabled: boolean}) => <ToolTemplate {...props} />;

const ToolTemplate: FC<{label: string; value: ToolName; disabled: boolean}> = observer(({label, value, disabled}) => {
  return (
    <div css={[$tool]}>
      <span className={toolIcons[value]} css={$mr} />
      <span>{label}</span>
    </div>
  );
});

const PolygonMoveControls: FC<{store: StoreInstance; tooltipPosition?: string}> = observer(
  ({store, tooltipPosition}) => {
    const locale = useLocale().controls;
    const onUndo = useCallback(() => store.itemStore.polygonStore.prevState(), [store]);
    const onRedo = useCallback(() => store.itemStore.polygonStore.nextState(), [store]);
    const onDelete = useCallback(() => {
      store.removeSelectedPolygon();
    }, [store]);
    const onFlip = useCallback(() => {
      if (store.selectedPolygon) {
        store.selectedPolygon.flip();
      }
    }, [store]);
    const onDirectionToggle = useCallback(() => {
      if (store.selectedPolygon) {
        if (store.selectedPolygon.direction) {
          store.selectedPolygon.setDirection(undefined);
        } else {
          store.selectedPolygon.setDirection([1, 0]);
        }
      }
    }, [store]);
    const onDirectedToggle = useCallback(() => {
      if (store.selectedPolygon) {
        store.selectedPolygon.toggleDirected();
      }
    }, [store]);
    const hasDirection = store.selectedPolygon?.direction !== undefined;
    return (
      <React.Fragment>
        <Button
          type={'button'}
          className="p-button-secondary p-button-outlined"
          icon={'mdi mdi-18px mdi-undo-variant'}
          tooltip={locale.undo}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.itemStore.polygonStore.canUndo}
          onClick={onUndo}
        />
        <Button
          type={'button'}
          className="p-button-secondary p-button-outlined"
          icon={'mdi mdi-18px mdi-redo-variant'}
          tooltip={locale.redo}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.itemStore.polygonStore.canRedo}
          onClick={onRedo}
        />
        <Button
          type={'button'}
          className={`${hasDirection ? 'p-button-primary' : 'p-button-secondary'} p-button-outlined`}
          icon={`mdi mdi-18px ${hasDirection ? 'mdi-sign-direction-minus' : 'mdi-sign-direction-plus'}`}
          tooltip={hasDirection ? locale.removeDirection : locale.addDirection}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.selectedPolygon}
          onClick={onDirectionToggle}
        />
        <Button
          type={'button'}
          className="p-button-secondary p-button-outlined"
          icon={`mdi mdi-18px ${store.selectedPolygon?.directed === true ? 'mdi-arrow-right' : 'mdi-arrow-left-right'}`}
          tooltip={store.selectedPolygon?.directed === true ? locale.oneDirection : locale.bothDirections}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.selectedPolygon || !hasDirection}
          onClick={onDirectedToggle}
        />
        <Button
          type={'button'}
          className="p-button-secondary p-button-outlined"
          icon={'mdi mdi-18px mdi-horizontal-rotate-clockwise'}
          tooltip={locale.flip}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.selectedPolygon || !hasDirection}
          onClick={onFlip}
        />
        <Button
          type={'button'}
          className="p-button-secondary p-button-outlined"
          icon={'mdi mdi-18px mdi-vector-polyline-remove'}
          tooltip={locale.delete}
          tooltipOptions={{position: tooltipPosition}}
          disabled={!store.selectedPolygon}
          onClick={onDelete}
        />
      </React.Fragment>
    );
  }
);

const LineMoveControls: FC<{store: StoreInstance; tooltipPosition?: string}> = observer(({store, tooltipPosition}) => {
  const locale = useLocale().controls;
  const onUndo = useCallback(() => store.itemStore.lineStore.prevState(), [store]);
  const onRedo = useCallback(() => store.itemStore.lineStore.nextState(), [store]);
  const onDelete = useCallback(() => {
    store.removeSelectedLine();
  }, [store]);
  const onFlip = useCallback(() => {
    if (store.selectedLine) {
      store.selectedLine.flip();
    }
  }, [store]);
  const onDirectedToggle = useCallback(() => {
    if (store.selectedLine) {
      store.selectedLine.toggleDirected();
    }
  }, [store]);
  return (
    <React.Fragment>
      <Button
        type={'button'}
        className="p-button-secondary p-button-outlined"
        icon={'mdi mdi-18px mdi-undo-variant'}
        tooltip={locale.undo}
        tooltipOptions={{position: tooltipPosition}}
        disabled={!store.itemStore.lineStore.canUndo}
        onClick={onUndo}
      />
      <Button
        type={'button'}
        className="p-button-secondary p-button-outlined"
        icon={'mdi mdi-18px mdi-redo-variant'}
        tooltip={locale.redo}
        tooltipOptions={{position: tooltipPosition}}
        disabled={!store.itemStore.lineStore.canRedo}
        onClick={onRedo}
      />
      <Button
        type={'button'}
        className="p-button-secondary p-button-outlined"
        icon={`mdi mdi-18px ${store.selectedLine?.directed === true ? 'mdi-arrow-right' : 'mdi-arrow-left-right'}`}
        tooltip={store.selectedLine?.directed === true ? locale.oneDirection : locale.bothDirections}
        tooltipOptions={{position: tooltipPosition}}
        disabled={!store.selectedLine}
        onClick={onDirectedToggle}
      />
      <Button
        type={'button'}
        className="p-button-secondary p-button-outlined"
        icon={'mdi mdi-18px mdi-horizontal-rotate-clockwise'}
        tooltip={locale.flip}
        tooltipOptions={{position: tooltipPosition}}
        disabled={!store.selectedLine}
        onClick={onFlip}
      />
      <Button
        type={'button'}
        className="p-button-secondary p-button-outlined"
        icon={'mdi mdi-18px mdi-vector-polyline-remove'}
        tooltip={locale.delete}
        tooltipOptions={{position: tooltipPosition}}
        disabled={!store.selectedLine}
        onClick={onDelete}
      />
    </React.Fragment>
  );
});
