import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {StoreInstance} from './Store';
import {createPaperProject} from './PaperProject';
import Controls from './Controls';
import {useLocale} from './Root';

export interface CanvasProps {
  store: StoreInstance;
  size: CanvasSize;
  controlsContainer?: HTMLElement;
  tooltipPosition?: string;
}

export interface CanvasSize {
  width: number;
  height: number;
  scale?: number;
  leftOffset?: number;
  topOffset?: number;
}

export default function Canvas({store, size, controlsContainer, tooltipPosition}: CanvasProps) {
  const editable = !!controlsContainer;
  const locale = useLocale();
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const {height, width, scale = 0, topOffset = 0, leftOffset = 0} = size;
    const canvasContainer = canvasContainerRef.current;
    if (canvasContainer) {
      const canvas = document.createElement('canvas');
      canvas.style.display = 'block';
      canvas.style.position = 'relative';
      canvas.style.top = topOffset + 'px';
      canvas.style.left = leftOffset + 'px';
      canvas.height = height;
      canvas.width = width;
      canvasContainer.appendChild(canvas);

      const cleanUp = createPaperProject(canvas, store, scale, editable, locale);
      return () => {
        cleanUp();
        canvas.remove();
      };
    }
    return undefined;
  }, [locale, store, editable, size]);
  return (
    <div ref={canvasContainerRef}>
      {store && controlsContainer
        ? ReactDOM.createPortal(<Controls store={store} tooltipPosition={tooltipPosition} />, controlsContainer)
        : null}
    </div>
  );
}
