import {useLayoutEffect, useRef} from 'react';
import {CanvasSize} from './Canvas';

export function useMountedRef() {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
}

export function calcCanvasSize(
  containerHeight: number,
  containerWidth: number,
  naturalHeight: number,
  naturalWidth: number
): CanvasSize {
  const heightZoom = containerHeight / naturalHeight;
  const widthZoom = containerWidth / naturalWidth;
  let height;
  let width;
  let scale;
  let topOffset;
  let leftOffset;
  if (heightZoom.toFixed(2) === widthZoom.toFixed(2)) {
    height = containerHeight;
    width = containerWidth;
    scale = widthZoom;
  } else if (heightZoom > widthZoom) {
    const clientActualHeight = widthZoom * naturalHeight;
    topOffset = (containerHeight - clientActualHeight) / 2;
    height = clientActualHeight;
    width = containerWidth;
    scale = widthZoom;
  } else {
    const clientActualWidth = heightZoom * naturalWidth;
    leftOffset = (containerWidth - clientActualWidth) / 2;
    height = containerHeight;
    width = clientActualWidth;
    scale = heightZoom;
  }
  return {
    width: Math.floor(width),
    height: Math.floor(height),
    scale,
    leftOffset,
    topOffset
  };
}
