import {addDisposer, destroy, Instance, SnapshotIn, SnapshotOut, types} from 'mobx-state-tree';
import {ToolName} from './tools/ToolName';
import historyPlugin from './HistoryPlugin';

export const PointModel = types
  .model('PointModel', {
    x: types.number,
    y: types.number
  })
  .actions((self) => {
    return {
      update(x: number, y: number) {
        self.x = x;
        self.y = y;
      }
    };
  });

export const SegmentModel = types.compose(
  'SegmentModel',
  PointModel,
  types.model({
    index: types.identifierNumber
  })
);

const PolygonModel = types
  .model('PolygonModel', {
    id: types.identifier,
    name: types.optional(types.string, ''),
    namePosition: types.optional(types.enumeration(['left', 'right', 'top', 'bottom', 'center']), 'center'),
    points:
      // types.refinement(
      types.array(SegmentModel),
    // (value) => (value?.length || 0) > 2, (value) => `Polygon [${value}] should have at least 3 points`),
    style: types.frozen({}),
    directed: types.maybe(types.boolean),
    direction: types.maybe(PointModel),
    editable: types.optional(types.boolean, false)
  })
  .actions((self) => {
    return {
      move(points: [number, number][]) {
        if (self.editable) {
          points.forEach(([x, y], i) => {
            const point = self.points[i];
            if (point) {
              point.update(x, y);
            }
          });
        } else {
          throw Error('polygon is not editable');
        }
      },
      movePoint(index: number, x: number, y: number) {
        if (self.editable) {
          const point = self.points[index];
          if (point) {
            point.update(x, y);
          }
        } else {
          throw Error('polygon is not editable');
        }
      },
      toggleDirected() {
        self.directed = !self.directed;
      },
      setDirection(point: [number, number] | undefined) {
        if (point === undefined) {
          self.direction = undefined;
          self.directed = undefined;
        } else {
          self.direction = PointModel.create({x: point[0], y: point[1]});
          if (self.directed === undefined) {
            self.directed = false;
          }
        }
      },
      flip() {
        if (self.direction) {
          self.direction.x = -self.direction.x;
          self.direction.y = -self.direction.y;
        }
      }
    };
  });

export const LineModel = types
  .model({
    id: types.identifier,
    name: types.optional(types.string, ''),
    start: SegmentModel,
    end: SegmentModel,
    editable: types.optional(types.boolean, false),
    directed: types.optional(types.boolean, false),
    style: types.frozen({})
  })
  .actions((self) => {
    return {
      moveStart(x: number, y: number) {
        if (self.editable) {
          self.start.update(x, y);
        } else {
          throw Error('ILine is not editable');
        }
      },
      moveEnd(x: number, y: number) {
        if (self.editable) {
          self.end.update(x, y);
        } else {
          throw Error('ILine is not editable');
        }
      },
      move(x1: number, y1: number, x2: number, y2: number) {
        if (self.editable) {
          self.start.update(x1, y1);
          self.end.update(x2, y2);
        } else {
          throw Error('ILine is not editable');
        }
      },
      toggleDirected() {
        self.directed = !self.directed;
      },
      flip() {
        if (self.editable) {
          const {x, y} = self.start;
          self.start.update(self.end.x, self.end.y);
          self.end.update(x, y);
        } else {
          throw Error('ILine is not editable');
        }
      }
    };
  });

const createItemsModel = <T extends typeof LineModel | typeof PolygonModel>(itemsModel: T) => {
  return types
    .model({
      items: types.array(itemsModel),
      editableLimit: types.optional(types.maybeNull(types.number), null)
    })
    .volatile(() => {
      let counter = 0;
      return {
        nextId() {
          return counter++;
        }
      };
    })
    .views((self) => {
      return {
        get editableItems() {
          return self.items.filter((l) => l.editable);
        }
      };
    })
    .views((self) => {
      return {
        get noItemsToEdit() {
          return self.editableItems.length === 0;
        },
        get editableLimitIsReached() {
          if (typeof self.editableLimit === 'number') {
            return self.editableLimit === self.editableItems.length;
          }
          return false;
        }
      };
    })
    .actions((self) => {
      return {
        afterCreate() {
          const limit = self.editableLimit;
          if (typeof limit === 'number') {
            const {editableItems} = self;
            editableItems.slice(0, editableItems.length - limit).forEach((p) => {
              p.editable = false;
            });
          }
        }
      };
    })
    .extend(historyPlugin);
};

const PolygonStore = types
  .compose(
    createItemsModel(PolygonModel),
    types.model({
      nameDefault: ''
    })
  )
  .actions((self) => {
    return {
      addPolygon(points: Array<{x: number; y: number}>) {
        if (!self.editableLimitIsReached) {
          const polygon = PolygonModel.create({
            id: self.nextId().toString(),
            name: self.nameDefault,
            points: points.map((point, index) => ({index, ...point})),
            editable: true
          });
          self.items.push(polygon);
          return polygon;
        } else {
          throw Error('Polygon editable limit cannot be exceeded');
        }
      }
    };
  });

const LineStore = types
  .compose(
    createItemsModel(LineModel),
    types.model({
      nameDefault: types.optional(types.string, ''),
      directedDefault: types.optional(types.boolean, false)
    })
  )
  // base actions
  .actions((self) => {
    return {
      addLine(start: [number, number], end: [number, number]) {
        if (!self.editableLimitIsReached) {
          const line = LineModel.create({
            id: self.nextId().toString(),
            name: self.nameDefault,
            start: {
              index: 0,
              x: start[0],
              y: start[1]
            },
            end: {
              index: 1,
              x: end[0],
              y: end[1]
            },
            editable: true,
            directed: self.directedDefault
          });
          self.items.push(line);
          return line;
        } else {
          throw Error('Polygon editable limit cannot be exceeded');
        }
      }
    };
  });

const ItemStore = types.model({
  polygonStore: PolygonStore,
  lineStore: LineStore
});

export const Store = types
  .model('Store', {
    itemStore: ItemStore,
    selectedLine: types.safeReference(LineModel),
    selectedPolygon: types.safeReference(PolygonModel),
    cursor: types.optional(types.string, 'auto'),
    activeTool: types.maybeNull(types.string)
  })
  .actions((self) => {
    return {
      selectTool(name: ToolName) {
        self.activeTool = name;
      },
      setSelectedPolygon(polygonModel: PolygonModelInstance | undefined) {
        self.selectedPolygon = polygonModel;
      },
      setSelectedLine(lineModel: LineModelInstance | undefined) {
        self.selectedLine = lineModel;
      },
      removeSelectedLine() {
        if (self.selectedLine) {
          destroy(self.selectedLine);
          self.selectedLine = undefined;
        }
      },
      removeSelectedPolygon() {
        if (self.selectedPolygon) {
          destroy(self.selectedPolygon);
          self.selectedPolygon = undefined;
        }
      }
    };
  })
  .actions((self) => {
    const cursorsStack = new Map<number, string>();
    const genID = (() => {
      let idCounter = 0;
      return () => {
        return idCounter++;
      };
    })();
    return {
      obtainCursor(newCursor: string) {
        const id = genID();
        cursorsStack.set(id, self.cursor);
        self.cursor = newCursor;
        return () => {
          (self as StoreInstance).releaseCursor(id);
        };
      },
      releaseCursor(id: number) {
        const value = cursorsStack.get(id);
        if (value) {
          const lastCursorId = [...cursorsStack.keys()].pop();
          if (id === lastCursorId) {
            self.cursor = value;
          }
          cursorsStack.delete(id);
        }
      }
    };
  })
  .volatile((self) => {
    return {
      createCursorHandler(name: string) {
        let release: (() => void) | null = null;
        let disposed = false;
        addDisposer(self, () => {
          disposed = true;
        });
        return {
          obtain() {
            this.release();
            if (!disposed) {
              release = self.obtainCursor(name);
            }
          },
          release() {
            if (!disposed) {
              if (release) {
                release();
              }
            }
            release = null;
          }
        };
      }
    };
  });

export type StoreInstance = Instance<typeof Store>;
export type StoreSnapshotIn = SnapshotIn<typeof Store>;
export type StoreSnapshotOut = SnapshotOut<typeof Store>;
export type PolygonStoreInstance = Instance<typeof PolygonStore>;
export type PolygonStoreSnapshotIn = SnapshotIn<typeof PolygonStore>;
export type PolygonStoreSnapshotOut = SnapshotOut<typeof PolygonStore>;
export type PolygonModelInstance = Instance<typeof PolygonModel>;
export type LineStoreInstance = Instance<typeof LineStore>;
export type LineStoreSnapshotIn = SnapshotIn<typeof LineStore>;
export type LineStoreSnapshotOut = SnapshotOut<typeof LineStore>;
export type LineModelInstance = Instance<typeof LineModel>;
export type ItemStoreInstance = Instance<typeof ItemStore>;
export type ItemStoreSnapshotIn = SnapshotIn<typeof ItemStore>;
export type ItemStoreSnapshotOut = SnapshotOut<typeof ItemStore>;
