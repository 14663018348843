import {PolygonModelInstance} from '../Store';
import {autorun} from 'mobx';
import {ItemType} from './ItemType';
import {renderTooltip, ITooltip} from './Tooltip';
import {IArrow, renderArrow} from './Arrow';
import {calcCentroid} from '../geometry/calcCentroid';

const DEFAULT_STROKE_COLOR = 'rgba(255, 60, 60, 1)';
const DEFAULT_ARROW_STROKE_WIDTH = 3;

export function isPolygon(item: paper.Item): item is IPolygon {
  return item.data.type === ItemType.polygon;
}

export interface IPolygon extends paper.Group {
  data: {
    readonly type: ItemType.polygon;
    readonly model: PolygonModelInstance;
    readonly polygon: paper.Path;
    readonly tooltip: ITooltip;
    readonly arrow: IArrow;
    readonly secondArrow: IArrow;
  };
}

export const getCustomStyle = (
  context: {scope: paper.PaperScope; scale: number},
  style: Partial<paper.Style>
): Partial<paper.Style> => {
  const {scope, scale} = context;
  let strokeColor: paper.Color;
  let fillColor: paper.Color;
  if (style.strokeColor) {
    strokeColor = style.strokeColor;
  } else {
    strokeColor = new scope.Color(DEFAULT_STROKE_COLOR);
  }
  if (style.fillColor) {
    fillColor = style.fillColor;
  } else {
    fillColor = new scope.Color(strokeColor);
    fillColor.alpha = 0.15;
  }
  return {
    ...style,
    strokeColor,
    fillColor,
    strokeWidth: (style.strokeWidth ?? 2) / scale
  };
};

export function renderPolygon(scope: paper.PaperScope, polygonModel: PolygonModelInstance, scale: number) {
  const context = {scope, scale};
  const disposers: Array<() => void> = [];
  const polygon = new scope.Path(polygonModel.points.map(({x, y}) => new scope.Point(x, y)));
  polygon.closePath();
  polygon.style = new scope.Style({
    ...polygon.style,
    ...getCustomStyle({scope, scale}, polygonModel.style as Partial<paper.Style>)
  });

  let tooltip = renderTooltip(scope, scale, polygon.position, polygonModel.name);

  const mainArrow = renderArrow(context);
  mainArrow.strokeColor = polygon.style.strokeColor;
  if (mainArrow.strokeColor) {
    mainArrow.strokeColor.hue += 120;
  }
  mainArrow.strokeWidth = DEFAULT_ARROW_STROKE_WIDTH / scale;
  const secondArrow = renderArrow(context);
  secondArrow.strokeColor = polygon.style.strokeColor;
  if (secondArrow.strokeColor) {
    secondArrow.strokeColor.hue += 120;
  }
  secondArrow.strokeWidth = mainArrow.strokeWidth;
  secondArrow.dashArray = [secondArrow.strokeWidth / 3, (secondArrow.strokeWidth * 20) / 15];
  secondArrow.strokeCap = 'round';

  const polygonGroup = new scope.Group([polygon, mainArrow, secondArrow, tooltip]);
  polygonGroup.data.type = ItemType.polygon;
  polygonGroup.data.model = polygonModel;
  polygonGroup.data.polygon = polygon;
  polygonGroup.data.arrow = mainArrow;
  polygonGroup.data.secondArrow = secondArrow;
  polygonGroup.data.tooltip = tooltip;

  disposers.push(
    autorun(() => {
      polygon.segments.forEach((segment, index) => {
        const point = new scope.Point(polygonModel.points[index].x, polygonModel.points[index].y);
        segment.point.set(point);
      });

      tooltip.remove();
      if (polygonModel.namePosition === 'bottom') {
        tooltip = renderTooltip(scope, scale, polygon.bounds.bottomCenter, polygonModel.name, 'center', 'bottom');
      } else {
        tooltip = renderTooltip(scope, scale, polygon.position, polygonModel.name, 'center');
      }
      polygonGroup.addChild(tooltip);
      polygonGroup.data.tooltip = tooltip;

      if (polygonModel.direction) {
        mainArrow.visible = true;
        secondArrow.visible = !polygonModel.directed;
        const {angle} = new scope.Point(polygonModel.direction);

        mainArrow.data.setStart(new scope.Point(calcCentroid(polygonModel.points)), 5 / scale);
        mainArrow.data.rotate(angle);
        secondArrow.data.setStart(new scope.Point(calcCentroid(polygonModel.points)), 5 / scale);
        secondArrow.data.rotate(angle + 180);
      } else {
        mainArrow.visible = false;
        secondArrow.visible = false;
      }
    })
  );

  disposers.push(() => {
    mainArrow.remove();
    tooltip.remove();
    // @ts-ignore
    polygon.data = null;
    polygon.remove();
    polygonGroup.remove();
  });

  return () => {
    disposers.forEach((d) => d());
  };
}
