import {observable} from 'mobx';
import {addDisposer, IPatchRecorder, onSnapshot, recordPatches} from 'mobx-state-tree';

const historyPlugin = <T>(self: T) => {
  const min = -1;
  const getMax = () => patches.length - 1;
  let currentFrame = observable.box(min);
  let skip = false;
  let patches: Array<IPatchRecorder> = [];
  let latestRecorder: IPatchRecorder = recordPatches(self);
  const resetRecorder = () => {
    latestRecorder.stop();
    latestRecorder = recordPatches(self);
  };
  addDisposer(
    self,
    onSnapshot(self, () => {
      if (!skip) {
        const current = currentFrame.get();
        const max = getMax();
        const advanceHistory = () => {
          patches.push(latestRecorder);
          currentFrame.set(current + 1);
          resetRecorder();
        };
        if (current >= min) {
          if (current === max) {
            advanceHistory();
          } else if (current < max) {
            patches.splice(current + 1);
            advanceHistory();
          }
        }
      }
      skip = false;
    })
  );
  return {
    views: {
      get canUndo() {
        return currentFrame.get() > min;
      },
      get canRedo() {
        return currentFrame.get() < getMax();
      }
    },
    actions: {
      prevState() {
        const current = currentFrame.get();
        if (current === -1) {
          return;
        }
        skip = true;
        patches[current].undo();
        currentFrame.set(current - 1);
        resetRecorder();
      },
      nextState() {
        const current = currentFrame.get();
        if (current === getMax()) {
          return;
        }
        skip = true;
        currentFrame.set(current + 1);
        patches[current + 1].replay();
        resetRecorder();
      }
    }
  };
};

export default historyPlugin;
